import { useCartAPI } from '../../hooks/api'

const state = () => ({
  cartMount: 0,
  cart: [],
  checkoutCart: {},
  checkoutIDList: {},
  totalAmountWithTax: 0
})

const mutations = {
  setCartMount: (state, { data }) => {
    state.cartMount = data
  },
  setCart: (state, { data }) => {
    state.cart = data
  },
  setCheckoutCart: (state, { data }) => {
    state.checkoutCart = data
  },
  setCheckoutIDList: (state, { data }) => {
    state.checkoutIDList = data
  }
}

const actions = {
  readCartMount: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/carts/qty/all/' + payload.memberID
    const data = { params: {} }

    try {
      const response = await useCartAPI({ method, path, data })
      const responseData = response.data?.data || 0
      commit('setCartMount', { data: responseData })
      return response
    } catch (error) {
      commit('setCartMount', { data: 0 })
      return Promise.reject(error)
    }
  },
  readCart: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/carts/' + payload.memberID
    const data = { params: { memberID: payload.memberID } }

    try {
      const response = await useCartAPI({ method, path, data })
      const responseData = response.data.data?.carts || []
      commit('setCart', { data: responseData })
      return response
    } catch (error) {
      commit('setCart', { data: [] })
      return Promise.reject(error)
    }
  },
  deleteCartItem: async ({ commit }, { payload }) => {
    const method = 'delete'
    const path = '/carts/' + payload.cartID
    const data = { params: {} }

    try {
      const response = await useCartAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  createCartItem: async ({ commit }, { payload }) => {
    const method = 'post'
    const path = '/carts/' + payload.memberID
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useCartAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  updateCartItem: async ({ commit }, { payload }) => {
    const method = 'put'
    const path = '/carts/qty/' + payload.cartID
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useCartAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  readCheckoutCart: async ({ commit }, { payload }) => {
    const method = 'post'
    const path = '/select_checkout_list'
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useCartAPI({ method, path, data })
      const responseData = response.data?.data || []
      commit('setCheckoutCart', { data: responseData })
      commit('setCheckoutIDList', { data: payload.idList })
      return response
    } catch (error) {
      commit('setCheckoutCart', { data: [] })
      commit('setCheckoutIDList', { data: [] })
      return Promise.reject(error)
    }
  }
}

const getters = {
  getCartMount: state => state.cartMount,
  getCart: state => state.cart,
  getCheckoutCart: state => state.checkoutCart,
  getCheckoutIDList: state => state.checkoutIDList
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
