import { useValidateAPI } from '../../hooks/api'

const state = () => ({
})

const mutations = {

}

const actions = {
  readKaptcha: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/web/kaptcha'
    const data = {
      params: { ...payload }
    }

    try {
      const response = await useValidateAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  updateResetPasswordRequest: async ({ commit }, { payload }) => {
    const method = 'put'
    const path = '/account/resetPwd/' + payload.token
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useValidateAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  createResetPasswordRequest: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = `/key/reset/${payload.resetPwdAccount}/${payload.accountType}`
    const data = { params: { } }

    try {
      const response = await useValidateAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  createRevalidationRequest: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = `/sendMemberActivation/${payload.validateMail}/${payload.accountType}`
    const data = { params: { } }

    try {
      const response = await useValidateAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  updateMemberActivate: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/member/activate/' + payload.memberID
    const data = {
      // ...payload
    }

    try {
      const response = await useValidateAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }

}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
