import { useOrderAPI } from '../../hooks/api'

const state = () => ({
  shipServices: [],
  orderID: '',
  order: {}
})

const mutations = {
  setShipServices: (state, { data }) => {
    state.shipServices = data
  },
  setOrderID: (state, { data }) => {
    state.orderID = data
  },
  setOrder: (state, { data }) => {
    state.order = data
  }
}

const actions = {
  readShipServices: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/shipServices'
    const data = {
      params: { ...payload }
    }

    try {
      const response = await useOrderAPI({ method, path, data })
      const responseData = response.data?.data?.shipServices || []
      commit('setShipServices', { data: responseData })
      return response
    } catch (error) {
      commit('setShipServices', { data: [] })
      return Promise.reject(error)
    }
  },
  readOrderID: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/getID'
    const data = {
      params: { ...payload }
    }

    try {
      const response = await useOrderAPI({ method, path, data })
      const responseData = response.data?.data?.orderID || ''
      commit('setOrderID', { data: responseData })
      return response
    } catch (error) {
      commit('setOrderID', { data: '' })
      return Promise.reject(error)
    }
  },
  readOrder: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/orders/' + payload.orderID
    const data = {
      params: { ...payload }
    }

    try {
      const response = await useOrderAPI({ method, path, data })
      const responseData = response.data?.data || {}
      commit('setOrder', { data: responseData })
      return response
    } catch (error) {
      commit('setOrder', { data: {} })
      return Promise.reject(error)
    }
  }
}

const getters = {
  getShipServices: state => state.shipServices,
  getOrderID: state => state.orderID,
  getOrder: state => state.order
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
