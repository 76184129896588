import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Front',
    component: () => import('../views/Front.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('../views/front/Home.vue')
      },
      {
        path: '/cart',
        name: 'Cart',
        component: () => import('../views/front/Cart.vue')
      },
      {
        path: '/checkout',
        name: 'Checkout',
        component: () => import('../views/front/Checkout.vue')
      },
      {
        path: '/order-confirm',
        name: 'OrderConfirm',
        component: () => import('../views/front/OrderConfirm.vue')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('../views/front/About.vue')
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('../views/front/validate/Login.vue')
      },
      {
        path: '/:clientID/reset/:token',
        name: 'ResetPassword',
        component: () => import('../views/front/validate/ResetPassword.vue'),
        props: route => ({
          clientID: route.params.clientID,
          token: route.params.token
        })
      },
      {
        path: '/:clientID/activate/:memberID',
        name: 'AccountActivate',
        component: () => import('../views/front/validate/AccountActivate.vue'),
        props: route => ({
          clientID: route.params.clientID,
          memberID: route.params.memberID
        })
      },
      {
        path: '/product/list',
        name: 'ProductList',
        component: () => import('../views/front/product/List.vue'),
        props: route => ({
          queryString: route.query.queryString,
          categoryID: route.query.categoryID,
          catMainID: route.query.catMainID,
          catSubID: route.query.catSubID
        })
      },
      {
        path: '/product/detail/:productID',
        name: 'ProductDetail',
        component: () => import('../views/front/product/Detail.vue'),
        props: route => ({
          productID: route.params.productID
        })
      },
      {
        path: '/member',
        name: 'Member',
        component: () => import('../views/front/Member.vue'),
        children: [
          {
            path: 'orders/:showComplete?',
            name: 'MemberOrders',
            component: () => import('../views/front/member/Orders.vue'),
            props: route => ({
              showComplete: route.params.showComplete === 'complete'
            })
          },
          {
            path: 'detail',
            name: 'MemberDetail',
            component: () => import('../views/front/member/Detail.vue')
          },
          {
            path: 'bonus/used',
            name: 'MemberBonusUsed',
            component: () => import('../views/front/member/BonusUsed.vue')
          },
          {
            path: 'bonus/added',
            name: 'MemberBonusAdded',
            component: () => import('../views/front/member/BonusAdded.vue')
          },
          {
            path: 'password',
            name: 'MemberPassword',
            component: () => import('../views/front/member/Password.vue')
          }]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
