import axios from 'axios'
import store from '@/store'

const api = axios.create({
  baseURL: process.env.VUE_APP_API
})

api.interceptors.request.use(
  request => {
    if (request.url === '/api_valid/web/kaptcha') {
      request.responseType = 'arraybuffer'
    }
    request.headers.clientID = process.env.VUE_APP_clientID
    request.withCredentials = true
    store.commit('setIsLoading', { data: true })
    return request
  },
  error => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => {
    store.commit('setIsLoading', { data: false })
    return response
  },
  error => {
    store.commit('setIsLoading', { data: false })
    return Promise.reject(error)
  }
)

const useAPI = ({ method, path, data }) => api[method](`${path}`, data)
const useOrderAPI = ({ method, path, data }) => api[method](`/api_order${path}`, data)
const useMemberAPI = ({ method, path, data }) => api[method](`/api_member${path}`, data)
const useContactAPI = ({ method, path, data }) => api[method](`/api_contact${path}`, data)
const useSystemAPI = ({ method, path, data }) => api[method](`/api_system${path}`, data)
const useProductAPI = ({ method, path, data }) => api[method](`/api_product${path}`, data)
const useCartAPI = ({ method, path, data }) => api[method](`/api_cart${path}`, data)
const useValidateAPI = ({ method, path, data }) => api[method](`/api_valid${path}`, data)

export {
  useAPI, useOrderAPI, useMemberAPI, useContactAPI, useProductAPI, useSystemAPI, useCartAPI, useValidateAPI
}
