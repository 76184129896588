import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import '@/assets/style/all.scss'
import { useIcon } from '@/hooks/useIcon.js'
import Icon from '@/components/Icon'

createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(useIcon)
  .component('Icon', Icon)
  .mount('#app')
