import { useSystemAPI, useAPI } from '../../hooks/api'

const state = () => ({
  clientID: null,
  bankAccountInfo: '',
  systemSettings: {},
  systemGASettings: {},
  systemFPSettings: {}
})

const mutations = {
  setClientID: (state, { data }) => {
    state.clientID = data
  },
  setBankAccountInfo: (state, { data }) => {
    state.bankAccountInfo = data
  },
  setSystemSettings: (state, { data }) => {
    state.systemSettings = data
  },
  setSystemGASettings: (state, { data }) => {
    state.systemGASettings = data
  },
  setSystemFPSettings: (state, { data }) => {
    state.systemFPSettings = data
  }
}

const actions = {
  readSystemSettings: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/client/settings/' + payload.clientID
    const data = {}

    try {
      const response = await useSystemAPI({ method, path, data })
      const responseData = response.data.data
      commit('setSystemSettings', { data: responseData || {} })
      commit('setClientID', { data: responseData?.clientID || null })
      return response
    } catch (error) {
      commit('setSystemSettings', { data: {} })
      commit('setClientID', { data: null })
      return Promise.reject(error)
    }
  },
  readGASystemSettings: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/client/g/' + process.env.VUE_APP_clientID
    const data = {}

    try {
      const response = await useSystemAPI({ method, path, data })
      const responseData = response.data.data
      commit('setSystemGASettings', { data: responseData || {} })
      commit('setClientID', { data: responseData?.clientID || null })
      return response
    } catch (error) {
      commit('setSystemGASettings', { data: {} })
      commit('setClientID', { data: null })
      return Promise.reject(error)
    }
  },
  readFPSystemSettings: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/client/f/' + process.env.VUE_APP_clientID
    const data = {}

    try {
      const response = await useSystemAPI({ method, path, data })
      const responseData = response.data.data
      commit('setSystemFPSettings', { data: responseData || {} })
      commit('setClientID', { data: responseData?.clientID || null })
      return response
    } catch (error) {
      commit('setSystemFPSettings', { data: {} })
      commit('setClientID', { data: null })
      return Promise.reject(error)
    }
  },
  readBankAccountInfo: async ({ commit }, { payload }) => {
    const method = 'get'
    const path = '/payways/bankAccountInfo'
    const data = {}

    try {
      const response = await useAPI({ method, path, data })
      const responseData = response.data.data?.bankAccountInfo
      commit('setBankAccountInfo', { data: responseData || '' })
      return response
    } catch (error) {
      commit('setBankAccountInfo', { data: '' })
      return Promise.reject(error)
    }
  },
  updateBankAccountInfo: async ({ commit }, { payload }) => {
    const method = 'put'
    const path = '/client/bankAccountInfo/' + payload.clientID
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useSystemAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  updateClientSetting: async ({ commit }, { payload }) => {
    const method = 'put'
    const path = '/client/info/' + payload.clientID
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useSystemAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  updateGASetting: async ({ commit }, { payload }) => {
    const method = 'put'
    const path = '/client/g/' + payload.clientID
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useSystemAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  updateFBSetting: async ({ commit }, { payload }) => {
    const method = 'put'
    const path = '/client/f/' + payload.clientID
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useSystemAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  updateEcpay: async ({ commit }, { payload }) => {
    const method = 'put'
    const path = '/client/ecpay/' + payload.clientID
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useSystemAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  updateNbpay: async ({ commit }, { payload }) => {
    const method = 'put'
    const path = '/client/nbpay/' + payload.clientID
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useSystemAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  updateCard0: async ({ commit }, { payload }) => {
    const method = 'put'
    const path = '/client/card0/' + payload.clientID
    const data = new FormData()
    for (const field in payload) {
      data.append(field, payload[field])
    }

    try {
      const response = await useSystemAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
  updatePayway: async ({ commit }, { payload }) => {
    const method = 'put'
    const path = `/client/payway/${payload.clientID}/${payload.paywayID}`
    const data = new FormData()
    for (const field in payload) {
      if (payload[field]) {
        if (field.includes('start') || field.includes('end')) {
          const date = new Date(payload[field])
          data.append(
            field,
            `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(
              -2
            )}-${`0${date.getDate()}`.slice(-2)} 00:00:00`
          )
        } else {
          data.append(field, payload[field])
        }
      }
    }

    try {
      const response = await useSystemAPI({ method, path, data })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const getters = {
  getSystemSettings: state => state.systemSettings,
  getSystemGASettings: state => state.systemGASettings,
  getSystemFPSettings: state => state.systemFPSettings,
  getBankAccountInfo: state => state.bankAccountInfo,
  getClientName: state => state.systemSettings?.clientName || '',
  getClientID: state => state.systemSettings?.clientID || ''
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
