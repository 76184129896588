<template>
  <router-view />
</template>

<script>
import { onMounted } from '@vue/runtime-core'
export default {
  components: {},
  setup () {
    function getButton () {
      // GetButton.io widget
      // 針對個別客戶做以下設定
      var options = {
        facebook: '110801484903043', // Facebook page ID
        line: '//line.me/ti/p/@373ynflf', // Line QR code URL
        call_to_action: '嗨 想和我們聯絡嗎?~', // Call to action
        button_color: '#FF6550', // Color of button
        position: 'right', // Position may be 'right' or 'left'
        order: 'line,facebook' // Order of buttons
      }
      // var proto = document.location.protocol
      // var host = 'https://localhost:80/'
      // var s = document.createElement('script')
      // s.type = 'text/javascript'
      // s.async = true
      // s.src = host + 'getButton.js'
      // s.onload = function () {
      //   window.WhWidgetSendButton.init(host, proto, options)
      // }
      // var x = document.getElementsByTagName('script')[0]
      // x.parentNode.insertBefore(s, x)
      var proto = document.location.protocol
      var host = 'https://demo1.omniec.com.tw/'
      // var url = proto + '//static.' + host
      var s = document.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = host + 'getButton.js'
      s.onload = function () {
        window.WhWidgetSendButton.init(host, proto, options)
      }
      var x = document.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
    }
    onMounted(() => {
      getButton()
    })

    return {
      getButton
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
